import Head from "next/head";

import { HomeScreen } from "@chapter/nightingale-base";

export default function Home() {
    return (
        <>
            <Head>
                <title>Chapter OTC</title>
            </Head>
            <div>
                <main>
                    <HomeScreen />
                </main>
            </div>
        </>
    );
}
